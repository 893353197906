//
// File uploader
// based on https://github.com/pqina/filepond
// --------------------------------------------------


.file-uploader {
  margin-bottom: 0;
  border: $border-width dashed $gray-400;
  border-radius: $border-radius-lg;
  font-family: $font-family-sans-serif;
  cursor: pointer;
}
.filepond--root .filepond--drop-label {
  min-height: 10rem;
  > label { cursor: pointer; }
}

.filepond--panel,
.filepond--panel-root {
  background-color: transparent !important;
}

.filepond--image-preview {
  background-color: $gray-900 !important;
}

.filepond--credits { display: none; }

@include media-breakpoint-up(sm) {
  .file-uploader-grid {
    .filepond--item {
      width: calc(50% - .5em);
    }
  }
}

[data-filepond-item-state*='error'] .filepond--item-panel,
[data-filepond-item-state*='invalid'] .filepond--item-panel {
    background-color: $danger;
}

[data-filepond-item-state='processing-complete'] .filepond--item-panel {
  background-color: $success;
}
