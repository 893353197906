//
// Parallax
// based on https://github.com/nk-o/jarallax
// --------------------------------------------------

.jarallax {
  position: relative;
  z-index: 0;
}
.jarallax .jarallax-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: {
    position: center !important;
    size: cover !important;
  }
}


// Disbale parallax in MS Edge and IE browsers

_:-ms-lang(x), .jarallax .jarallax-img {
  margin: 0 !important;
  position: absolute !important;
  transform: none !important;
}


// Cursor parallax

.parallax {
  position: relative;
}
.parallax-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  > img {
    display: block;
    width: 100%;
  }
}
